import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ButtonLink from '../../components/ui/Buttons/ButtonLink';
import ThemenGrid, {Tiles} from './ThemenGrid';


const ThemenBlock = () => {

    const data = useStaticQuery(staticQuery);

    let tiles: Array<Tiles> = [];

    tiles = data.allFallbeispieleHJson.edges.map(({node}) => ({...node, type: "fallbeispiel", published: new Date(node.published), }));
    
    tiles = [
        ...tiles,
        ...data.allLandingpagesHJson.edges.map(({node}) => ({
            type: "landingpage",
            published: new Date(node.published),
            name: node.name,
            kurzbeschreibung: node.description,
            url: node.url,
            listenbild: node.listenbild, 
        })),
        ...data.allNeuigkeitenHJson.edges.map(({node}) => ({
            type: "news",
            published: new Date(node.published),
            name: node.title,
            kurzbeschreibung: node.kurzbeschreibung,
            url: node.url,
            tags: node.tags,
        }))
    ];

    tiles = tiles.sort((a, b) => b.published.getTime() - a.published.getTime());

    return <div className="columns">

    <div className="column is-one-quarter">
        <div className="content">
            <h2 className="title">Unsere Themen</h2>
            <p>
                Aktuelles aus unserer täglichen Arbeit zusammengefasst. Erfahren sie alles rund um unsere Produkte, Projekte sowie Neuigkeiten.
            </p>
            <ButtonLink to="/themen">Mehr Themen</ButtonLink>
        </div>
    </div>


    <div className="column">
        <ThemenGrid tiles={tiles.slice(0, 6)} />
    </div>


</div>

}

export default ThemenBlock;


export const staticQuery = graphql`{
  allFallbeispieleHJson(
    limit: 3
    filter: {published: {ne: null}}
    sort: {fields: published, order: DESC}
  ) {
    edges {
      node {
        published
        kurzbeschreibung
        name
        url
        listenbild {
          alt
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  allNeuigkeitenHJson(
    limit: 4
    filter: {published: {ne: null}}
    sort: {fields: published, order: DESC}
  ) {
    edges {
      node {
        published
        title
        url
        kurzbeschreibung
        tags
      }
    }
  }
  allLandingpagesHJson(
    limit: 3
    filter: {published: {ne: null}}
    sort: {fields: published, order: DESC}
  ) {
    edges {
      node {
        published
        name
        url
        listenbild {
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          alt
        }
        description
      }
    }
  }
}
`;