import React from 'react';
import { Breakpoints } from '../../../utils/Types';
import * as styles from './Collapse.module.scss';
import './Collapse.scss';
import classnames from 'classnames';
import {camelCase} from 'lodash';

type Props = {
    children: any;
    title: string;
    behaviourOn?: Array<Breakpoints>;
    showTitleOnlyOnBehaviour?: boolean;
}

export default function Collapse(props: Props) {
    const {children, title, showTitleOnlyOnBehaviour} = props;
    const behaviourOn: Array<Breakpoints> = props.behaviourOn ? props.behaviourOn : ["mobile", "tablet"];
    const allBreaktpionts: Array<Breakpoints> = ["mobile", "tablet", "desktop"];

    const [open, setOpen] = React.useState(false);

    const toggle = () => {
        setOpen(open => !open);
    }

    return <div className={classnames(behaviourOn.map(breakpoint => styles[camelCase("is-collapsable-" + breakpoint)]))}>
        <h2 
            className={classnames([
                "title", 
                styles.title, 
                open ? styles.titleOpen : "", 
                showTitleOnlyOnBehaviour ? allBreaktpionts.map(bp => (behaviourOn.indexOf(bp) === -1 ? "is-hidden-" + bp : "")) : "",
                ])} 
                onClick={toggle}
            >
            {title}
            <svg className={classnames([styles.verticalFoldChevron, open ? styles.verticalFoldChevronOpen : styles.verticalFoldChevronClosed])} width="18px" height="10px" viewBox="0 0 9 5" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M7.646,0.375l-3.616,3.687l-3.655,-3.687" style={{fill: "none", fillRule:"nonzero", stroke: "#009ee0", strokeWidth: 0.75}} /></svg>
        </h2>
        <div className={classnames([styles.content, open ? styles.isOpen : "", styles.isClosed])}>
            {children}
        </div>
    </div>
}