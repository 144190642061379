import React from 'react';
import * as styles from './ScrollDownCircle.module.scss';
import classnames from 'classnames';

type Props = {
    id: string;
    indend?: 1 | 2;
    afterWave?: boolean;
}

export default function ScrollDownCircle (props: Props) {
    const { id, indend, afterWave } = props;

    const scrollTo = (e) => {
        const anchor = e.currentTarget.getAttribute("href").replace("#", "");
        const section = document.getElementById(anchor);

        e.preventDefault();
        if (section && typeof window !== `undefined`) {
            window.scroll({top: section.offsetTop, left: 0, behavior: 'smooth' });
        }
        
    }

    return <a 
        href={"#" + id} 
        onClick={scrollTo} 
        className={classnames([
            styles.circle, 
            indend ? styles["indend" + indend] : "",
            afterWave ? styles.afterWave : "",
        ])}
    >
            <svg className="verticalFoldChevron" width="18px" height="10px" viewBox="0 0 9 5" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M7.646,0.375l-3.616,3.687l-3.655,-3.687" style={{fill: "none", fillRule:"nonzero", stroke: "#009ee0", strokeWidth: 0.4}} /></svg>
    </a>
}