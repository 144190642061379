import React from 'react';
import { TabItemProps } from './TabItem';
import {kebabCase} from 'lodash';
import * as styles from './Tabs.module.scss';
import classnames from 'classnames';

type Props = {
    children: React.ReactElement<TabItemProps>[];
}

type TabContainer = {
    key: string;
    name: string;
    content: React.ReactElement;
}

export default function Tabs (props: Props) {
    const {children} = props;

    const container: Array<TabContainer> = children.map(child => ({
            key: kebabCase(child.props.menu),
            name: child.props.menu,
            content: child.props.children,
    }));

    const [active, setActive] = React.useState<string>(container[0].key);

    return <div>
        <div className={classnames([styles.menu, "is-hidden-mobile"])}>
            {container.map((item, index) => (
                <a 
                    key={index}
                    href="#" 
                    onClick={(e) => { e.preventDefault(); setActive(item.key)}}
                    className={item.key === active ? styles.aactive : ""}
                >
                    {item.name}
                </a>
            ))}
        </div>

        {container.map((item, index) => {

            return <div key={index} className={classnames([styles.content, item.key === active ? styles.active : ""])}>
                    {item.content}
            </div>
        })}
    </div>
}