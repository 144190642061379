import React from 'react';
import * as styles from './Notification.module.scss';
import './Notification.scss';
import { Link, navigate  } from 'gatsby';
import classnames from 'classnames';
// import WeihnachtenSvg from '../../../data/images/notifications/weihnachten.svg';
import NotificationSvg from '../../../data/images/notifications/corona-2020.svg';

type Props = {
}

export default function Notification (props: Props) {
    const {} = props;

    // const data = useStaticQuery(graphql`
    //     {
    //         icon: file(relativePath: {regex: "/corona-2020.jpg/"}) {
    //             relativePath
    //             childImageSharp {
    //                 fluid {
    //                     ...GatsbyImageSharpFluid_withWebp
    //                 }
    //             }
    //         }
    //     }
    // `);

    return <></>;

    const linkTo = "/unternehmen/jobs-karriere";

    return <div className={classnames(["notificationBanner", "section", styles.wrapper])}>
        <div className={classnames(["container", styles.pointer])} onClick={() => { navigate(linkTo); }}>
            <div className={classnames(["columns is-mobile is-vcentered"])}>
                <div className={classnames(["column", "is-offset-2-desktop", "is-hidden-mobile", "is-narrow", styles.column])}>
                        <img className={styles.image} src={NotificationSvg} alt="Wichtige Meldung" />
                </div>

                <div className="column is-half-desktop">
                    <div className={"content"}>
                        {/* JOB AUSSCHREIBUNG */}
                        {/* <strong>Telefonstörung!</strong>  Wir sind leider von einer Telefonstörung betroffen. Sie erreichen uns unter: <a href="tel:004915901982263">01590 1982263</a> */}

                        {/* JOB AUSSCHREIBUNG */}
                        <Link to={linkTo} className={styles.link}>
                            <strong>Du möchtest eine neue berufliche Herausforderung? </strong>  <span className={styles.underline}>Bewirb dich</span> und werde Teil unseres Teams!
                        </Link>
                        
                        {/* WEIHNACHTEN */}
                        {/* <Link to="/unternehmen" className={styles.link}>
                            <strong>Wir wünschen ein frohes Fest</strong> und einen guten Rutsch ins neue Jahr! 
                            Wir gehen am 21.12.2020 in unseren Betriebsurlaub und sind am 07.01.2021 wieder für euch da!
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
}