// extracted by mini-css-extract-plugin
export var verticalFoldChevron = "Collapse-module--verticalFoldChevron--1KXvT";
export var isCollapsableMobile = "Collapse-module--is-collapsable-mobile--3mK_r";
export var title = "Collapse-module--title--29Ksv";
export var wrapper = "Collapse-module--wrapper--3UG4E";
export var content = "Collapse-module--content--2-Igt";
export var titleOpen = "Collapse-module--titleOpen--3wght";
export var isClosed = "Collapse-module--isClosed--3Z5-u";
export var isOpen = "Collapse-module--isOpen--12Zir";
export var verticalFoldChevronClosed = "Collapse-module--verticalFoldChevronClosed--3m4A5";
export var verticalFoldChevronOpen = "Collapse-module--verticalFoldChevronOpen--3KZMt";
export var isCollapsableTablet = "Collapse-module--is-collapsable-tablet--3XfbM";
export var isCollapsableDesktop = "Collapse-module--is-collapsable-desktop--30hF5";
export var spinAround = "Collapse-module--spinAround--ukhg_";