import React from 'react';

export type TabItemProps = {
    children: any;
    menu: string;
}

export default function TabItem (props: TabItemProps) {

    return <>

    </>
}