import React from "react"
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout, { IMetadata } from "../components/Layout";
import Hero from "../components/ui/Hero/Hero";
import Section from "../components/ui/Section/Section";
import HorizontalScroll from "../components/ui/HorizontalScroll/HorizontalScroll";
import * as styles from './index.module.scss';
import * as stylesProdukte from "./produkte/index.module.scss";
import AufkleberIcon from "../assets/aufkleber.inline.svg";
import SchilderIcon from "../assets/schilder.inline.svg";
import TextilienIcon from "../assets/textilien.inline.svg";
import WerbetechnikIcon from "../assets/werbetechnik.inline.svg";
import ZubehoerIcon from "../assets/zubehoer.inline.svg";
import ButtonLink from "../components/ui/Buttons/ButtonLink";
import Tabs from "../components/ui/Tabs/Tabs";
import TabItem from "../components/ui/Tabs/TabItem";
import IlluMaterialien from "../assets/illu_materialien.svg";
import IlluDruckBeschriftung from "../assets/illu_druckverfahren-und-beschriftungen.svg";
import IlluWeiterverarbeitung from "../assets/illu_weiterverarbeitung.svg";
import ScrollDownCircle from "../components/ui/ScrollDownCircle/ScrollDownCircle";
import Collapse from "../components/ui/Collapse/Collapse";
import classnames from 'classnames';
import ThemenBlock from "../components/themen/ThemenBlock";
import SvgImage from "../components/SvgImage";
import Notification from "../components/ui/Notification/Notification";

const metadata: IMetadata = {
    description: "30 Jahre Erfahrung im Bereich Werbetechnik und Beschriftung macht uns zu Ihrem Partner für Schilder, Aufkleber und mehr. Wir bieten Ihnen die optimale Lösung!",
    title: "Ihr Partner in Werbetechnik und Beschriftung",
}

export default ({ data }) => {
    return (
        <Layout crumbs={[{ label: "", url: "" }]} hasWave={false} breadcrumbColor="light" classNameHeader={styles.header} metadatas={metadata}>
            <Notification />
            <Hero
                color="dark"
                colorDesktop="dark"
                hasWaveBottom={["mobile", "tablet"]}
                className={styles.hero}
            >
                <div className="columns">
                    <div className="column is-1 is-hidden-touch"></div>
                    <div className="column">
                        <h1>
                                Hallo, <Link to="/unternehmen">wir</Link> sind HIS Print & Service.
                                Wir haben in über 30 Jahren alles Wissen im Bereich der <Link to="/produkte/werbetechnik"> Werbetechnik </Link> 
                                und Beschriftung, wie <Link to="/produkte/schilder">Schilder</Link> oder <Link to="/produkte/aufkleber">Aufkleber</Link> in unterschiedlichsten Anwendungen, angeeignet.
                                Unser Ziel ist es unser Wissen anzubieten um jedem eine optimale <Link to="/fallbeispiele">Lösung</Link> zu bieten.
                                {/* Bei uns sind sie gern gut beraten - rundum! */}
                        </h1>
                    </div>
                    <div className="column is-1 is-hidden-touch"></div>
                </div>
            </Hero>

            <div className="container">
                <ScrollDownCircle id="leistungssprektrum" indend={1}  afterWave={true} />
            </div>


            <Section size="small" id="leistungssprektrum" hasTitle={true}>
                <div className={styles.leistungenTeaser}>
                    <h2>Unser Leistungs&shy;spektrum</h2>
                    <span className="subtitle">Von Anfang bis Ende in guter Hand</span>
                </div>

                <Tabs>
                    <TabItem menu="Material">
                        <Collapse title="Unsere Materialvielfalt" behaviourOn={["mobile"]} showTitleOnlyOnBehaviour={true}>
                            <div className="columns is-vcentered">
                                <div className={classnames(["column is-half", styles.leistungenPictureFirst])}>
                                    <SvgImage src={IlluMaterialien} alt="Materialkombinationen" ratio={0.87} />
                                </div>
                                <div className="column">
                                    <div className="content">
                                        <h3 className="is-hidden-mobile">Unsere Materialvielfalt</h3>
                                        <p>
                                            Um auf jede Anforderung agieren zu können sind unterschiedliche Materialien erforderlich. Diese gilt es geschickt zu kombinieren. Erfahren Sie alles über unsere
                                            Materialien die wir einsetzen. Wir sind auch immer auf der Suche nach Neuem und testen ständig für unsere Kunden.
                                        </p>
                                        <ButtonLink to="/leistungen/materialien">Zu unseren Materialien</ButtonLink>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </TabItem>
                    <TabItem menu="Druckverfahren & Beschriftung">
                        <Collapse title="Unsere Druckverfahren" behaviourOn={["mobile"]} showTitleOnlyOnBehaviour={true}>
                            <div className="columns is-vcentered">
                                <div className={classnames(["column is-half", styles.leistungenPictureFirst])}>
                                    <SvgImage src={IlluDruckBeschriftung} alt="Druck und Beschriftungsverfahren" ratio={0.67} />
                                </div>
                                <div className="column">
                                    <div className="content">
                                        <h3 className="is-hidden-mobile">Unsere Druckverfahren</h3>
                                        <p>
                                            Unterschiedliche Materialen erfordern unterschiedliche Beschriftungsmethoden. Unser Maschinenpark ist deshalb vielfälltig und 
                                            ideal miteinander kombinierbar. So können funktionale bis hin zu besonders edle Beschriftungen und Drucke entstehen.
                                        </p>
                                        <ButtonLink to="/leistungen/druckverfahren">Zu unseren Druckverfahren</ButtonLink>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </TabItem>
                    <TabItem menu="Weiterverarbeitung">
                        <Collapse title="Unsere Weiterverarbeitung" behaviourOn={["mobile"]} showTitleOnlyOnBehaviour={true}>
                            <div className="columns is-vcentered">
                                <div className={classnames(["column is-half", styles.leistungenPictureFirst])}>
                                    <SvgImage src={IlluWeiterverarbeitung} alt="Weiterverarbeitungen" ratio={0.67} />
                                </div>
                                <div className="column">
                                    <div className="content">
                                        <h3 className="is-hidden-mobile">Unsere Weiterverarbeitung</h3>
                                        <p>
                                            Ein vollendetes Produkt kommt um einer Weiterverarbeitung nicht vorbei. Hier schaffen wir Funktion wie etwa verbesserte Haltbarkeit oder Design, indem wir Form ins Spiel bringen.
                                        </p>
                                        <ButtonLink to="/leistungen/weiterverarbeitung">Zu unseren Weiterverarbeitungen</ButtonLink>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </TabItem>
                </Tabs>
            </Section>

            <Hero
                color="dark-blue"
                size="is-medium"
                hasWaveBottom={["mobile", "tablet", "desktop"]}
                hasWaveTop={["mobile", "tablet", "desktop"]}
                waveBottomColor="white"
                waveTopColor="white"
            >
                <div className="columns">
                    <div className="column is-1 is-hidden-touch"></div>
                    <div className="column">

                        <div className="columns">
                            <div className="column is-three-quarters">
                                <h2 className="title">Unsere Produkt&shy;vielfalt</h2>
                                <span className="subtitle">
                                    Stöbern Sie hier durch unsere verschiedenen Produkttypen. Wir beliefern gerne Ihr Unternehmen mit bestehenden Produkten aber auch für individuelle,
                                    spezialisierte Artikel sind wir der ideale Partner.
                                </span>
                            </div>
                        </div>

                        <div className="column">

                        <HorizontalScroll breakpoints={["mobile"]} itemsOnWidth={2}>
                            <div className="columns is-mobile">
                                <div className="column is-one-fifth">
                                    <Link to="/produkte/schilder" className={stylesProdukte.link}>
                                        <SchilderIcon />
                                        <span className="is-size-4">Schilder</span>
                                    </Link>
                                </div>
                                <div className="column is-one-fifth">
                                    <Link to="/produkte/aufkleber" className={stylesProdukte.link}>
                                        <AufkleberIcon />
                                        <span className="is-size-4">Aufkleber</span>
                                    </Link>
                                </div>
                                <div className="column is-one-fifth">
                                    <Link to="/produkte/textilien" className={stylesProdukte.link}>
                                        <TextilienIcon />
                                        <span className="is-size-4">Textilien</span>
                                    </Link>
                                </div>
                                <div className="column is-one-fifth">
                                    <Link to="/produkte/werbetechnik" className={stylesProdukte.link}>
                                        <WerbetechnikIcon />
                                        <span className="is-size-4">Werbe&shy;technik</span>
                                    </Link>
                                </div>
                                <div className="column is-one-fifth">
                                    <Link to="/produkte/zubehoer" className={stylesProdukte.link}>
                                        <ZubehoerIcon />
                                        <span className="is-size-4">Zubehör</span>
                                    </Link>
                                </div>
                            </div>
                        </HorizontalScroll>

                        </div>

                        <div className="has-text-right content">
                            <ButtonLink to="/produkte">Zur Produktvielfalt</ButtonLink>

                        </div>
                    </div>
                    <div className="column is-1 is-hidden-touch"></div>
                </div>
            </Hero>

            <Section size="extraSmall" hasTitle={true}>

                <div className="columns">
                    <div className="column">
                        <GatsbyImage image={data.team.childImageSharp.gatsbyImageData} alt="Das HIS Team" />
                    </div>

                    <div className="column">
                        <div className="content">
                            <h2 className="title">Wir sind ein Familien&shy;betrieb</h2>
                            <p>
                                Gegründet und geführt von Willi Hoffmann hat sich HIS Print & Service zum angesehenen Partner für Beschriftungen entwickelt. Natürlich klappt
                                dies nur mit einem gut funktionierenden Team. Am besten, Sie lernen uns gleich kennen!
                            </p>
                            <ButtonLink to="/unternehmen">Mehr über uns</ButtonLink>
                        </div>
                    </div>
                </div>

            </Section>
           
           
            <Section  hasTitle={true}>
                <ThemenBlock />
            </Section>


        </Layout>
    );}

export const pageQuery = graphql`{
  team: file(relativePath: {regex: "/team.jpg/"}) {
    relativePath
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`;